<template>
    <modal show="show" >
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-if="type == 'Edit'"> Update {{ size.docType }} </h6>
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-else>Add {{ size.docType }} </h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">

                    
                    <div v-if="size.isVariant === false && size.valueType==='Date'"  class="form-group has-label col-sm-12 ">
                        <label class="text  font-weight-bolder">Select Date:<span class="text-danger"> *</span> </label>
                        <datepicker v-model="size.name"></datepicker>
                        
                        <span v-if="$v.size.name.$error" class="error">
                            <span v-if="!$v.size.name.required"> {{ $t('AddSize.NameRequired') }}</span>
                            <span v-if="!$v.size.name.maxLength">{{ $t('AddSize.NameRequired') }}</span>
                        </span>
                    </div>
                    <div v-else-if="size.isVariant === false && size.valueType==='Color'"  class="form-group has-label col-sm-12 ">
                        <label class="text  font-weight-bolder">Select Color:<span class="text-danger"> *</span> </label>
                        <ColorPicker v-model="$v.size.name.$model" :color="size.name"></ColorPicker>
                        <span v-if="$v.size.name.$error" class="error">
                            <span v-if="!$v.size.name.required"> {{ $t('AddSize.NameRequired') }}</span>
                            <span v-if="!$v.size.name.maxLength">{{ $t('AddSize.NameRequired') }}</span>
                        </span>
                    </div>
                    <div v-else-if="size.isVariant === false && size.valueType==='Number'" class="form-group has-label col-sm-12 ">
                        <label class="text  font-weight-bolder">Number:<span class="text-danger"> *</span> </label>
                        <input class="form-control" v-model="$v.size.name.$model" type="number"/>
                        <span v-if="$v.size.name.$error" class="error">
                            <span v-if="!$v.size.name.required"> {{ $t('AddSize.NameRequired') }}</span>
                            <span v-if="!$v.size.name.maxLength">{{ $t('AddSize.NameRequired') }}</span>
                        </span>
                    </div>
                    <div v-else class="form-group has-label col-sm-12 ">
                        <label class="text  font-weight-bolder">Name:<span class="text-danger"> *</span> </label>
                        <input class="form-control" v-model="$v.size.name.$model" />
                        <span v-if="$v.size.name.$error" class="error">
                            <span v-if="!$v.size.name.required"> {{ $t('AddSize.NameRequired') }}</span>
                            <span v-if="!$v.size.name.maxLength">{{ $t('AddSize.NameRequired') }}</span>
                        </span>
                    </div>

                    <div v-if="size.docType=='Variant'" class="form-group has-label col-sm-12 " v-bind:class="{ 'has-danger': $v.size.productType.$error }">
                        <label class="text  font-weight-bolder"> Product Type: </label>

                        <multiselect v-model="size.productType" :preselect-first="true"
                            :options="['Specific', 'All Product']" :show-labels="false"
                            :placeholder="$t('AddCustomer.SelectType')">
                        </multiselect>
                    </div>
                    <div v-if="size.docType=='Variant'" class="form-group has-label col-sm-12 " v-bind:class="{ 'has-danger': $v.size.valueType.$error }">
                        <label class="text  font-weight-bolder"> Value Type:</label>

                        <multiselect v-model="size.valueType" :preselect-first="true"
                            :options="['Text', 'Number', 'Date', 'Text Area', 'Color']" :show-labels="false"
                            :placeholder="$t('AddCustomer.SelectType')">
                        </multiselect>



                    </div>
                    

                    <div class="form-group has-label col-sm-12 " v-bind:class="{ 'has-danger': $v.size.description.$error }">
                        <label class="text  font-weight-bolder"> {{ $t('AddSize.Description') }}: </label>
                        <textarea rows="3" class="form-control" v-model="$v.size.description.$model" type="text" />
                        <span v-if="$v.size.description.$error" class="error">{{ $t('AddSize.descriptionLength') }}</span>
                    </div>


                    <div class="form-group col-md-4">
                        <div class="checkbox form-check-inline mx-2">
                            <input type="checkbox" id="inlineCheckbox1" v-model="size.isActive">
                            <label for="inlineCheckbox1"> {{ $t('AddSize.Active') }} </label>
                        </div>
                    </div>



                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveSize"
                    v-bind:disabled="$v.size.$invalid" v-if="type != 'Edit' && isValid('CanAddSize')">{{ $t('AddSize.btnSave')
                    }}</button>
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveSize"
                    v-bind:disabled="$v.size.$invalid" v-if="type == 'Edit' && isValid('CanEditSize')">{{
                        $t('AddSize.btnUpdate') }}</button>
                <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">{{ $t('AddSize.btnClear')
                }}</button>
            </div>
            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
        </div>



    </modal>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Multiselect from 'vue-multiselect'

import { required, maxLength } from "vuelidate/lib/validators"
export default {
    mixins: [clickMixin],
    props: ['show', 'size', 'type'],
    components: {
        Loading,
        Multiselect,

    },
    data: function () {
        return {
            render: 0,
            arabic: '',
            english: '',
            loading: false,
        }
    },
    validations: {
        size: {
            name: {
                required,
                maxLength: maxLength(250)
            },
            productType: {
                maxLength: maxLength(250)
            },
            valueType: {

                maxLength: maxLength(250)
            },
            code: {
            },
            description: {
                maxLength: maxLength(500)
            }
        }
    },
    methods: {
       

       

        

        close: function () {
            this.$emit('close');
        },
        
        SaveSize: function () {
            var root = this;
            this.loading = true;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveSize', this.size, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {

                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {

                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                        else {

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update!' : 'تم التحديث!',
                                icon: 'success',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update Successfully!' : 'تم التحديث بنجاح',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            icon: 'error',
                            text: "Your Size Name  Already Exist!",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false);
        }
    },
    mounted: function () {
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        if (this.size.id == '00000000-0000-0000-0000-000000000000' || this.size.id == undefined || this.size.id == '')
            {
                console.log("ok");
            }

      
    }
}
</script>
